@import './_svg';

.header {
	position: relative;
	z-index: 11;
	background-color: #fff;
	& > ul {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
		justify-content: space-around;
		align-items: center;
		max-width: 1270px;
		margin: 0 auto;
		li {
			justify-self: center;
			&:last-of-type {
				padding: 1rem 0;
			}
			a {
				font-size: 18px;
				font-weight: 700;
				color: #595959;
				display: block;
				text-align: center;
				letter-spacing: 1px;
			}
		}
	}
	.tel {
		margin: 0 0 .5rem;
		&::before {
			// content: url('data:image/svg+xml, ' + $telIcon);
			content: url('../assets/svg/tel-icon.svg');
			vertical-align: middle;
			margin-right: .5rem;
			margin-bottom: 1rem;
		}
	}
	.navCta {
		border-radius: 1.25rem;
		background-color: #595959;
		padding: 8px 20px;
		font-size: 16px;
		font-weight: 500;
		color: white;
		&:hover {
			opacity: .9;
		}
	}
}
.navbar {
	position: sticky;
	top: -1px;
	z-index: 10;
	width: 100%;
	background-color: rgba(61, 61, 61, .9);
	& > ul {
		display: flex;
		justify-content: center;
		align-items: center;
		max-width: 1270px;
		margin: 0 auto;
		& > li {
			height: 100%;
			padding: 1rem 0;
			position: relative;
			a {
				color: #fff;
				font-size: 16px;
				padding: 0 1rem;
				text-transform: uppercase;
				&::after {
					content: '';
					display: block;
					height: 3px;
					background-color: #bebbbc;
					transition: all .3s ease;
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					margin: auto;
					width: 0;
				}
			}
			&:hover {
				background-color: rgba(180, 4, 4, .6);
				a {
					&::after {
						width: 100%;
					}
				}
			}
		}
	}
	& > button {
		display: none;
		position: absolute;
		top: 50%;
		right: 0;
		transform: translate(-50%, -50%);
		svg {
			color: #fff;
		}
	}
}

@media (max-width: 991px) {
	.navbar {
		height: 51px;
		background-color: rgba(61, 61, 61, .9);
		& > ul {
			overflow: hidden;
			flex-direction: column;
			position: absolute;
			width: 100%;
			background-color: rgba(61, 61, 61, .9);
			padding: 1rem 0;
			margin-top: 51px;
			z-index: 2;
			bottom: 0;
			transition: all .5s ease-in-out;
			&[data-menu-status="closed"]  {
				
				opacity: 0;
				visibility: hidden;
				& > li {
					opacity: 0;
					visibility: hidden;
					max-height: 51px;
				}
			}
			&[data-menu-status="open"] {
				opacity: 1;
				visibility: visible;
				transform: translateY(100%);
				& > li {
					width: 100%;
					padding: 1rem;
				}
				& + button {
					right: 7px;
					z-index: 1;
					&::after {
						content: '';
						position: absolute;
						left: auto;
						right: -20px;
						top: -15px;
						bottom: 0;
						width: calc(100vw + 1px);
						height: 100vh;
						padding-bottom: 100vh;
						z-index: -1;
					}
				}
			}
		}
		& > button {
			display: block;
			top: 29px;
		}
	}
}
