.hero-content-center {
   height: 100%;
   min-height: calc(70vh + 1px);
   max-height: calc(70vh + 1px);
   position: relative;
   .overlay-dark {
      height: 100%;
      min-height: calc(70vh + 1px);
      max-height: calc(70vh + 1px);
      position: relative;
      img {
         border-radius: 0 0 5% 5%;
         // filter: brightness(.6);
         object-position: center 65%;
      }
   }
   .hero-content-text {
      width: 100%;
      // max-width: 840px;
      padding: 1rem .5rem;
      color: #fff;
      position: absolute;
      top: 50%;
      // left: 50%;
      // transform: translate(-50%, -50%);
      // text-align: center;
      left: 5%;
      text-align: left;
      max-width: 610px;
      transform: translate(0, -50%);
      h1 {
         font-size: calc(1rem + 2.8vmin);
         margin: 0 0 1rem;
      }
      h2 {
         font-size: calc(1rem + .4vmin);
         font-weight: 500;
         margin: 0 0 clamp(1.75rem, 4vw, 2.5rem);
         max-width: 80%;
         line-height: 1.5;
      }
      a {
         padding: 1rem 1.5rem;
         background-color: rgba(180, 4, 4, .6);;
         color: #fff;
         font-weight: 700;
         &:hover {
            background-color: rgba(180, 4, 4, 1);
         }
      }
   }
}
.hero-content-center-secondary {
   min-height: calc(30vh + 1px);
   position: relative;
   .overlay-dark {
      min-height: calc(30vh + 1px);
      position: relative;
      img {
         filter: brightness(.7);
         object-position: bottom center;
      }
   }
   .hero-content-text {
      width: 100%;
      max-width: 840px;
      padding: 1rem .5rem;
      color: #fff;
      position: absolute;
      top: 60%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      h1 {
         font-size: calc(1rem + 2.8vmin);
         margin: 0 0 1rem;
      }
      h2 {
         font-size: calc(1rem + .4vmin);
         font-weight: 500;
         margin: 0 0 clamp(1.75rem, 4vw, 2.5rem);
      }
      a {
         padding: 1rem 1.5rem;
         background-color: rgba(180, 4, 4, .6);;
         color: #fff;
         font-weight: 700;
         &:hover {
            background-color: rgba(180, 4, 4, 1);
         }
      }
   }
}
@media (max-width: 767px) {
   .hero-content-center {
      min-height: calc(55vh + 1px);
      .overlay-dark {
         min-height: calc(55vh + 1px);
         img {
            filter: brightness(.6);
         }
      }
      
   }
}