.btn {
   background-color: rgba(0, 97, 254, .8);
   color: #fff !important;
   min-height: 44px;
   padding: 0 1rem;
   height: 100%;
   &:hover {
      background-color: rgba(0, 97, 254, 1); 
      &::after {
         transform: translateX(10px) scale(1.1);
      }
   }
   &::after {
      content: url('data:image/svg+xml, ' + $arrowRight);
      margin-left: 1rem;
   }
}
.btn-outline {
   background-color: #fff;
   color: #000;
   min-height: 44px;
   padding: 0 1rem;
   height: 100%;
   border: 1px solid rgba(0, 97, 254, .8);
   border-radius: 4px;
   width: 100%;
   &:hover {
      background-color: rgba(0, 97, 254, 1);
      color: #fff;
   }
}
.btn-dark-outline {
   border-radius: 3px;
   border: 1px solid #000;
   background: none;
   font-size: 14px;
   color: #000 !important;
   padding: .5rem 1.5rem;
   &:hover {
      background-color: #bc2227;
      color: #fff !important;
      border-color: #bc2227;
   }
}
.link-cta {
   font-size: 14px;
   font-weight: 200;
   color: #155A93;
   display: block;
   margin-top: auto;
   padding-top: 1rem;
   &:visited {
      color: #155A93;
   }
   &:hover {
      color: #bc2227;
   }
   &::after {
      content: '➔';
      margin-left: .5rem;
   }
}
.btn-primary {
   display: inline-block;
   position: relative;
   padding: 1rem 1.5rem;
   margin: 1rem 0;
   background-color: rgba(180, 4, 4, 0.6);
   color: #fff;
   font-weight: 700;
}